class CurlClass {
    /**
     * Constructor of the class
     * @param block
     */
    constructor(block) {
        // CURL Container block
        this.container = block;
        // Section element
        this.section = this.container.closest('.o-block');

        this._initProps();
        this._initElements();
        this._initStates();
        this._initListeners();
    }

    _initProps() {
        // Class of the main block
        this.componentClass = 'o-services-board-a-1';
        // Data from backend
        // URL for ajax requests
        this.ajaxUrl = window.curl.url;
        // Name of the action
        this.ajaxAction = window.curl.action;
        // Nonce code for ajax requests
        this.ajaxNonce = window.curl.nonce;
        // Time between updates in seconds
        this.curlTimeout = 30;
        // Gender to show
        this.currentGender = 'any';
    }

    _initElements() {
        // Loader of the block
        this.loader = this.container.querySelector(`[data-role='loader']`);
        // Projects container
        this.projectsContainer = this.container.querySelector(
            `[data-role='projects']`,
        );
        // Updated Note
        this.updatedNote = this.container.querySelector(
            `[data-role='updated-note']`,
        );
        // Title
        this.title = this.section.querySelector(
            `.${this.componentClass}__title`,
        );
    }

    _initStates() {
        // Source of the data
        this.currentSource = this.container.getAttribute('data-source');
        // Gender to show
        this.currentGender = this.container.getAttribute('data-gender');
    }

    _initListeners() {
        this._initCurl();
    }

    /**
     * Sending AJAX on BACK and showing response on Front
     * @private
     */
    _initCurl() {
        // For mixing JS with jQuery
        let classExemplar = this;

        $.ajax({
            type: 'POST',
            url: this.ajaxUrl,
            data: {
                action: this.ajaxAction,
                _nonce: this.ajaxNonce,
                source: this.currentSource,
                gender: this.currentGender,
            },
            beforeSend: function () {},
            success: function (response) {
                // Response Data
                let data = response.data;
                if (!data || !('output' in data)) {
                    return;
                }
                classExemplar.projectsContainer.innerHTML = data.output;
                classExemplar.updatedNote.innerHTML =
                    classExemplar._getNowDate();
                classExemplar._updateTitle(data.volunteers_total_number);
            },
            complete: function (xhr) {
                // Commit this function again
                setTimeout(function () {
                    classExemplar._initCurl();
                }, 1000 * classExemplar.curlTimeout);
            },
            error: function (xhr) {
                console.log('error', xhr);
            },
        });
    }

    /**
     * Return Date-Time String in needed format
     * @returns {string}
     * @private
     */
    _getNowDate() {
        const today = new Date();
        const date = `${
            today.getMonth() < 9
                ? '0' + (today.getMonth() + 1)
                : today.getMonth() + 1
        }/${
            today.getDate() < 10 ? '0' + today.getDate() : today.getDate()
        }/${today.getFullYear()}`;
        const time = `${
            today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
        }:${
            today.getMinutes() < 10
                ? '0' + today.getMinutes()
                : today.getMinutes()
        }:${
            today.getSeconds() < 10
                ? '0' + today.getSeconds()
                : today.getSeconds()
        }`;
        return `${date} ${time}`;
    }

    /**
     * Updating title - show the total number of volunteers
     * @param volunteersNumber
     * @private
     */
    _updateTitle(volunteersNumber) {
        if (typeof volunteersNumber !== 'number' || !this.title) {
            return;
        }

        this.title.innerText = this.title.innerText.replace(
            /\[X\]/g,
            volunteersNumber,
        );
    }
}

function servicesBoardA1() {
    const curlContainers = document.querySelectorAll(
        `[data-role='curl-container']`,
    );
    if (curlContainers.length) {
        curlContainers.forEach((block) => {
            new CurlClass(block);
        });
    }
}

export default servicesBoardA1;
